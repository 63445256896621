import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/layout';
import Seo from '../components/seo';

import { contactsStatic } from '../db/contactsStatic';
// import { contactsData } from '../db/contactsData';
import CrumbsNav from '../components/UiComponents/CrumbsNav/CrumbsNav';
import Contacts from '../components/Pages/Contacts/Contacts';

const ContactsPage = ({data}) => {
  return (
    <Layout theme={'black'}>
      <Seo 
        title={data.strapiContacts.seo_title} 
        description={data.strapiContacts.seo_decription}
      />

      <Contacts contactsData={data.strapiContacts} />
      <CrumbsNav dataCrumbs={contactsStatic.crumbsNav} />
    </Layout>
  );
};

export default ContactsPage;

export const query = graphql`
  query {
    strapiContacts {
      seo_title
      seo_description
      title
      Socials {
        title
        link
      }
      Contacts_list {
        position
        phone
        map_link
        name
        address
        mail
      }
    }
  }
`