import React from 'react';
import PropTypes from 'prop-types';
import Arrow from '../../../svg/arrow_top_right_grey.svg';

const Contacts = ({ contactsData }) => {
  return (
    <>
      <section className="contacts-main" id="top-section" data-scroll-section>
        <div className="content-wrapper">
          <h1 className="contacts-main__title">{contactsData.title}</h1>
        </div>
      </section>
      <section className="contacts-content" data-scroll-section>
        <div className="content-wrapper">
          {contactsData.Contacts_list.map((item, index) => {
            const count = ++index;
            return (
              <div key={index} className="contacts-content__item">
                <span className="contacts-content__item-counter">
                  {'0' + count + ' /'}
                </span>

                <div className="contacts-content__item-name">
                  <p className="contacts-content__item-title">{item.name}</p>
                  {item.position ? (
                    <span className="contacts-content__item-subtitle">
                      {item.position}
                    </span>
                  ) : null}
                </div>

                <div className="contacts-content__item-info">
                  {item.phone ? (
                    <div className="item-info__phone-wrapper content__item-info__wrapper">
                      <span className="item-info__phone-marker">Phone :</span>
                      <a
                        href={`tel:` + item.phone.split(' ').join('')}
                        className="item-info__phone"
                      >
                        {item.phone}
                      </a>
                    </div>
                  ) : null}
                  {item.mail ? (
                    <div className="item-info__mail-wrapper content__item-info__wrapper">
                      <span className="item-info__mail-marker">Email :</span>
                      <a
                        href={'mailto:' + item.mail}
                        className="item-info__mail"
                      >
                        {item.mail}
                      </a>
                    </div>
                  ) : null}
                  {item.address ? (
                    <div className="item-info__address-wrapper">
                      <p className="item-info__address">{item.address}</p>{' '}
                      <a
                        href={item.map_link}
                        target="blank"
                        className="item-info__map"
                      >
                        <span className="item-info__map-title">
                          Open in Google Map
                        </span>
                        <Arrow />
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

Contacts.propTypes = {
  contactsData: PropTypes.object,
};

export default Contacts;
